import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"row gx-0 flex-grow-1\">\n  <div class=\"col-3\">\n    <OesNavigation @model={{@model}} @avc={{@avc}} />\n  </div>\n  <div class=\"col-9 d-flex flex-grow-1\">\n    {{#if this.activeVc}}\n      <VisualizationComponentRenderer\n        @configuredComponent={{this.activeVc}}\n        @readOnly={{@model.internalView.module.readOnly}}\n      />\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class=\"row gx-0 flex-grow-1\">\n  <div class=\"col-3\">\n    <OesNavigation @model={{@model}} @avc={{@avc}} />\n  </div>\n  <div class=\"col-9 d-flex flex-grow-1\">\n    {{#if this.activeVc}}\n      <VisualizationComponentRenderer\n        @configuredComponent={{this.activeVc}}\n        @readOnly={{@model.internalView.module.readOnly}}\n      />\n    {{/if}}\n  </div>\n</div>","moduleName":"client-app-omnivise-web/components/oes-view.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/oes-view.hbs"}});
import Component from '@glimmer/component';
import { type Registry as Services, service } from '@ember/service';
import { cached } from '@glimmer/tracking';

export interface OesViewSignature {
  // The arguments accepted by the component
  Args: {
    model: any;
    avc: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OesView extends Component<OesViewSignature> {
  @service declare urlState: Services['url-state'];

  // Assumes that data is loaded through the model hook
  @cached
  get configuredComponentsRecords() {
    const widgets = this.args.model.internalView
      .hasMany('configuredWidgets')
      .value();

    const records = widgets.reduce((acc, widget) => {
      const ccs = widget.hasMany('configuredComponents').value();
      return acc.concat(ccs.slice());
    }, []);

    return records;
  }

  get activeVc() {
    return (
      this.configuredComponentsRecords.find((cc) => cc.id === this.args.avc) ??
      this.configuredComponentsRecords[0]
    );
  }
}
