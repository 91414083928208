import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"container\">\n  <div class=\"row justify-content-center\">\n    {{#each @model.internalView.configuredWidgets as |configuredWidget|}}\n      {{#each configuredWidget.configuredComponents as |configuredComponent|}}\n        <div class=\"col-auto my-2\">\n          <button\n            type=\"button\"\n            class=\"btn\n              {{if\n                (eq configuredComponent.id @avc)\n                'btn-primary'\n                'btn-secondary'\n              }}\"\n            {{on\n              \"click\"\n              (fn this.emitActiveVcChangeEvent configuredComponent.id)\n            }}\n          >\n            {{t (concat \"oes.\" configuredComponent.id)}}\n            -\n            {{configuredComponent.componentDescriptor.releaseChannel}}\n          </button>\n        </div>\n      {{/each}}\n    {{/each}}\n  </div>\n</div>", {"contents":"<div class=\"container\">\n  <div class=\"row justify-content-center\">\n    {{#each @model.internalView.configuredWidgets as |configuredWidget|}}\n      {{#each configuredWidget.configuredComponents as |configuredComponent|}}\n        <div class=\"col-auto my-2\">\n          <button\n            type=\"button\"\n            class=\"btn\n              {{if\n                (eq configuredComponent.id @avc)\n                'btn-primary'\n                'btn-secondary'\n              }}\"\n            {{on\n              \"click\"\n              (fn this.emitActiveVcChangeEvent configuredComponent.id)\n            }}\n          >\n            {{t (concat \"oes.\" configuredComponent.id)}}\n            -\n            {{configuredComponent.componentDescriptor.releaseChannel}}\n          </button>\n        </div>\n      {{/each}}\n    {{/each}}\n  </div>\n</div>","moduleName":"client-app-omnivise-web/components/oes-navigation.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/oes-navigation.hbs"}});
import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import { type Registry as Services, service } from '@ember/service';

import { action } from '@ember/object';

export interface OesNavigationSignature {
  // The arguments accepted by the component
  Args: {
    model: any;
    avc: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OesNavigation extends Component<OesNavigationSignature> {
  @service declare router: RouterService;
  @service declare store: Services['store'];

  // TODO: new concept of active vc that is rendered in the same view
  // This event will change active vc that is rendered in the same view
  // This updates the URL query parameter of the VIEW route.
  // Maybe it even needs is own new view type and route.
  @action
  emitActiveVcChangeEvent(ccId: string): void {
    this.router.transitionTo({
      queryParams: {
        avc: ccId,
      },
    });
  }
}
